import { combineReducers } from 'redux';

import priceReducer from './price';
import transactionsReducer from './transactions';
import tokensReducer from './tokens';

export const rootReducer = combineReducers({
  priceReducer,
  transactionsReducer,
  tokensReducer,
});
